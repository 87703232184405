import React, { Component } from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import App from './App'

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <App />
      </Switch>
    </Router>
  )
};

export default AppRouter;
