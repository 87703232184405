import React, { Component, useState, useEffect } from 'react';
import { render } from 'react-dom';
import {
  useParams,
  useLocation
} from "react-router-dom";
import queryString from 'query-string';
import _ from 'lodash';


import { Pannellum, PannellumVideo } from "pannellum-react";

import { Typography, Divider, Modal } from 'antd';


const App = () => {

  let topicId = '{"url":"/images/360render-4k.jpeg","title":"ห้องคุณพิไล","subtitle":"ตกแต่งสไตล์โมเดิร์น เน้นโทนสีเทาเข้าให้บรรยากาศพักผ่อน","hotspots":[{"pitch":-0.8298557695539949,"yaw":-159.81473376932365,"text":"กระจก","url":"https://google.co.th"},{"pitch":-5.167093268400182,"yaw":164.49464642814672,"text":"ทีวี 5K 16k฿","url":"https://google.co.th"},{"pitch":-31.740438007668775,"yaw":42.97667754786867,"text":"เตียงขนมิ้ง 40.5k฿","url":"https://google.co.th"},{"pitch":4.737229680874618,"yaw":103.75067287330896,"text":"ผ้าม่าย 1450฿ ต่อตารางเมตร","url":"https://google.co.th"},{"pitch":23.830481186501057,"yaw":163.09024911000157,"text":"ตู้ลอย พร้อมติดตั้ง 4800฿","url":"https://google.co.th"},{"pitch":2.137862472941779,"yaw":-68.86247212165475,"text":"ตู้เสื้อผ้า สูง 2.5 เมตร ประตูกระจก 44800฿","url":"https://google.co.th"},{"pitch":-6.503642114131492,"yaw":-15.394406487602208,"text":"โคมไฟ แสงสีขาวนวล 680฿","url":"https://google.co.th"}]}'

  const [ error, setError ] = useState(false)
  const [ title, setTitle ] = useState()
  const [ subtitle, setSubtitle ] = useState()
  const [ url, setUrl ] = useState()
  const [ hotspots, setHotspots ] = useState([])
  const [ fullScreen, setFullScreen ] = useState(false)

  const location = useLocation()

  useEffect(() => {
    try {
      const search = queryString.parse(_.get(location, 'search'))
      let rawUrl = _.get(search, 'url', undefined)
      if (rawUrl != undefined) {
        setFullScreen(true)
        setUrl(rawUrl)
        return
      }

      const setting = JSON.parse(search.setting)
      setTitle(_.get(setting, 'title', undefined))
      setSubtitle(_.get(setting, 'subtitle', undefined))
      setHotspots(_.get(setting, 'hotspots', []))
      setUrl(_.get(setting, 'url', undefined))
    }
    catch (ex) {
      console.log(ex)
      setError(true)
    }
  }, [])






  return (
    <div style={{ padding: 20 }}>

      {
        (error != true) &&
        <div>
          {title &&
            <Typography.Title>{title}</Typography.Title>
          }
          {subtitle &&
            <Typography.Text>{subtitle}</Typography.Text>
          }
          {
            (title || subtitle) &&
            <Divider />
          }
          <Pannellum
            width="100%"
            height={fullScreen ? "95vh" : "70vh"}
            image={url}
            pitch={0}
            yaw={0}
            hfov={90}
            // hotspotDebug={true}
            autoLoad
            onLoad={() => {
                console.log("panorama loaded");
            }}
            onError={(err) => {
              setError(true)
            }}
          >
            {
              hotspots.map((d) => {
                return (
                  <Pannellum.Hotspot
                    type="info"
                    pitch={_.get(d, 'pitch', 0)}
                    yaw={_.get(d, 'yaw', 0)}
                    text={_.get(d, 'text', "")}
                    URL={_.get(d, 'url', "")}
                  />
                )
              })
            }
          </Pannellum>
        </div>
      }
      <Modal
        title="ไม่พบไฟล์ภาพในระบบ"
        centered
        closable={false}
        visible={error}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>ไม่สามารถเข้าถึงไฟล์ภาพ กรุณาสอบถามผู้ดูแลระบบ</p>
      </Modal>
    </div>
  )
};

export default App;
